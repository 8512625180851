/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {Table, Tooltip, Button, Modal} from "antd";
import FormSearchFilter from "../../components/FormSearchFilter";
import moment from "moment";
import APIService from "../../service/APIService";
import { priceFormat, isCSKH } from "../../utils";
import { useSelector } from "react-redux";

export default function PaymentBankDuyetPage() {
  const [dataTable, setDataTable] = useState([]);
  const [filter] = useState({});
  const user = useSelector((state) => state.user.userState);
  const [dataMoneyLog, setDataMoneyLog] = useState([]);
  const [open, setOpen] = useState(false);

  async function getMoneyLogList(player) {
    try {
      const data = await APIService.moneyLogList(player, "", "");
      if (data) {
        setDataMoneyLog(data);
        setOpen(true);
      }
    } catch (error) {
      console.error(error);
    }
  }

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      width: 90,
      key: "id",
    },

    {
      title: "USER",
      dataIndex: "player",
      width: 130,
      key: "player",
      render: (data, data1, index) => (
        <>
          {data}
          <Button
            className="text-center"
            type="text"
            danger
            onClick={() => {
              getMoneyLogList(data);
            }}
          >
            LSC
          </Button>
        </>
      ),
    },
    {
      title: "USERNAME",
      dataIndex: "username",
      width: 130,
      key: "username",
    },
    {
      title: "MỆNH GIÁ",
      dataIndex: "menhGia",
      width: 120,
      key: "menhGia",
      render: (data) => priceFormat(data),
    },
    {
      title: "TRẠNG THÁI",
      dataIndex: "status",
      width: 140,
      align: "center",
      key: "status",
      render: (data) => {
        switch (data) {
          case 0:
            return <p className="text-green-400	font-semibold">Chờ duyệt</p>;
          case 1:
            return <p className="text-green-400	font-semibold">Thành công</p>;
          case 2:
            return <p className="text-black-400	font-semibold">Không duyệt</p>;
          case 3:
            return <p className="text-black-400	font-semibold">Chờ cổng</p>;
          default:
            return <p className="text-green-400	"></p>;
        }
      },
    },
    {
      title: "DUYỆT TAY",
      dataIndex: "duyetTay",
      width: 140,
      align: "center",
      key: "duyetTay",
      render: (data) => {
        switch (data) {
          case -1:
            return <p className="text-red-400	font-semibold">Lỗi</p>;
          case 0:
            return <p className="text-green-400	font-semibold">Bình thường</p>;
          case 1:
            return <p className="text-green-400	font-semibold">Thành công</p>;
          case 2:
            return <p className="text-black-400	font-semibold">Không duyệt</p>;
          case 3:
            return <p className="text-black-400	font-semibold">Chờ duyệt</p>;
          default:
            return <p className="text-green-400	"></p>;
        }
      },
    },
    {
      title: "CẬP NHẬT LÚC",
      dataIndex: "updatedAt",
      width: 180,
      key: "updatedAt",
      align: "center",
      render: (data, data1, index) => (
        <p>{moment(data).format("DD-MM-YYYY HH:mm:ss")}</p>
      ),
    },
    {
      title: "DUYỆT",
      dataIndex: "",
      key: "",
      align: "center",
      width: 250,
      render: (data, data1, index) => {
        if (data1.duyetTay === 3 && data1.activeApprove === true) {
          return (
            <>
              <Button
                className="text-center"
                type="primary"
                onClick={() => {
                  confirmBank(data1.orderid, 1);
                }}
              >
                Duyệt
              </Button>
              &nbsp;
              <Button
                className="text-center"
                type="primary"
                onClick={() => {
                  confirmBank(data1.orderid, 0);
                }}
              >
                Không Duyệt
              </Button>
            </>
          );
        } else return "";
      },
    },
    {
      title: "ĐỔI TRẠNG THÁI",
      dataIndex: "",
      key: "",
      align: "center",
      width: 550,
      render: (data, data1, index) => {
        if (data1.duyetTay === 3) {
          return (
            <>
              <Button
                className="text-center"
                type="primary"
                onClick={() => {
                  changeBankStatus(data1.orderid, 1);
                }}
              >
                Thành Công
              </Button>
              &nbsp;
              <Button
                className="text-center"
                type="primary"
                onClick={() => {
                  changeBankStatus(data1.orderid, 0);
                }}
              >
                Thất Bại
              </Button>
              &nbsp;
              <Button
                className="text-center"
                type="primary"
                onClick={() => {
                  changeSunStatus(data1.orderid);
                }}
              >
                Rút từ Sun
              </Button>
              &nbsp;
              <Button
                className="text-center"
                type="primary"
                onClick={() => {
                  changeHitStatus(data1.orderid);
                }}
              >
                Rút từ Hit
              </Button>
            </>
          );
        } else return "";
      },
    },
    {
      title: "MÃ NGÂN HÀNG",
      dataIndex: "bankCode",
      width: 140,
      key: "bankCode",
    },
    {
      title: "NGÂN HÀNG",
      dataIndex: "bankName",
      width: 150,
      key: "bankName",
    },
    {
      title: "Short",
      dataIndex: "bankShort",
      width: 80,
      key: "bankShort",
    },
    {
      title: "CHI TIẾT",
      dataIndex: "detail",
      key: "detail",
      width: 250,
      ellipsis: {
        showTitle: false,
      },
      render: (data) => (
        <Tooltip placement="topLeft" title={data}>
          {data}
        </Tooltip>
      ),
    },
    {
      title: "MÃ GIAO DỊCH",
      dataIndex: "orderid",
      width: 180,
      key: "orderid",
    },
    {
      title: "NGƯỜI GỬI",
      dataIndex: "sender",
      width: 140,
      align: "center",
      key: "sender",
    }
  ];

  const columnsMoneyLog = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 140,
      sorter: (a, b) => a.id - b.id,
      align: "center",
    },

    {
      title: "GAME",
      width: 140,
      dataIndex: "game",
      key: "game",
    },
    {
      title: "LOG",
      width: 250,
      dataIndex: "log",
      key: "log",
      ellipsis: {
        showTitle: false,
      },
      render: (data) => (
        <Tooltip placement="topLeft" title={data}>
          {data}
        </Tooltip>
      ),
      align: "center",
    },
    {
      title: "TIỀN",
      width: 160,
      dataIndex: "gold",
      key: "gold",
      render: (data) => priceFormat(data),
    },
    {
      title: "TIỀN THẮNG/THUA",
      dataIndex: "updateGold",
      key: "updateGold",
      sorter: (a, b) => a.updateGold - b.updateGold,
      render: (data) => priceFormat(data),
      width: 200,
    },
    {
      title: "DETAIL",
      dataIndex: "detail",
      key: "detail",
      width: 250,
      ellipsis: {
        showTitle: false,
      },
      render: (data) => (
        <Tooltip placement="topLeft" title={data}>
          {data}
        </Tooltip>
      ),
    },
    {
      title: "TIME",
      dataIndex: "time",
      key: "time",
      width: 200,
      align: "center",
      sorter: (a, b) => moment(a.time).valueOf() - moment(b.time).valueOf(),
      render: (data, data1, index) => (
        <p>{moment(data).format("DD-MM-YYYY HH:mm:ss")}</p>
      ),
    },
  ];

  async function getPaymentBankDuyetList() {
    try {
      const data = await APIService.paymentBankDuyetList();
      if (data) {
        for (let i = 0; i < data.length; i++) data[i].activeApprove = true;
        // cskh chỉ cho phép xem trong n ngày gần nhất
        if (isCSKH(user)) {
          let to = new Date();
          to.setHours(23);
          to.setMinutes(59);
          to.setSeconds(59);
          let from = new Date();
          from.setDate(
            to.getDate() - process.env.REACT_APP_LIMIT_CSKH_DATE_NUMBER
          );
          from.setHours(0);
          from.setMinutes(0);
          from.setSeconds(0);
          for (let i = 0; i < data.length; i++) {
            // không cho phép duyệt mệnh giá từ 1tr
            if (data[i].menhGia >= 2000000) data[i].activeApprove = false;
            let createdDate = moment(data[i].timestamp);
            let limitDate = moment(from);
            if (createdDate.isBefore(limitDate)) {
              data.splice(i, 1);
              i--;
            }
          }
        }
        setDataTable(data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function confirmBank(orderid, type) {
    try {
      let text = "Bạn có muốn DUYỆT giao dịch bank này không ?";
      if (type === 0)
        text = "Bạn có muốn KHÔNG DUYỆT giao dịch bank này không ?";
      if (window.confirm(text) === true) {
        const data = await APIService.confirmBank(orderid, type);
        if (data) getPaymentBankDuyetList();
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function changeBankStatus(orderid, status) {
    try {
      let text =
        "Bạn có muốn chuyển trạng thái THÀNH CÔNG giao dịch bank này không ?";
      if (status === 0)
        text =
          "Bạn có muốn chuyển trạng thái THẤT BẠI giao dịch bank này không ?";
      if (window.confirm(text) === true) {
        const data = await APIService.changeBankStatus(orderid, status);
        if (data) getPaymentBankDuyetList();
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function changeSunStatus(orderid) {
    try {
      let text =
        "Bạn có muốn chuyển trạng thái RÚT TỪ SUN giao dịch bank này không ?";
      if (window.confirm(text) === true) {
        const data = await APIService.changeSunStatus(orderid);
        if (data) getPaymentBankDuyetList();
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function changeHitStatus(orderid) {
    try {
      let text =
        "Bạn có muốn chuyển trạng thái RÚT TỪ HIT giao dịch bank này không ?";
      if (window.confirm(text) === true) {
        const data = await APIService.changeHitStatus(orderid);
        if (data) getPaymentBankDuyetList();
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getPaymentBankDuyetList();
  }, []);

  return (
    <>
      <FormSearchFilter
        title="Duyệt Bank"
        initialValues={filter}
      ></FormSearchFilter>
      <Table columns={columns} dataSource={dataTable} />

      <Modal
        title={dataMoneyLog[0]?.player}
        centered
        open={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        width={1100}
        footer={false}
      >
        <Table columns={columnsMoneyLog} dataSource={dataMoneyLog} />
      </Modal>
    </>
  );
}
